import styled from 'styled-components'

export default styled.div`
    width: ${(props) => props.fullWidth ? '100%' : '70%'};
    width: ${(props) => props.myWidth};
    text-align:center;
    height: ${(props) => props.fixedHeight ? '200px' : ''};
    @media (max-width: 768px) {
           width: ${(props) => props.smWidth};
        }
`;