import styled from "styled-components"

export default styled.div`
    background-image: url(${(props) => props.image});
    width: 100%;
    height:480px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${(props) => props.position};
    object-fit:cover;
`;