import styled from 'styled-components'

export default styled.div`
    background-color: ${(props) => props.color};
    margin: auto;
    height: 74.5px;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 6%;
    color: white;
    font-family:"Avenir-Black";
`;