import styled from 'styled-components'

export default styled.h1`
    background-color: ${(props) => props.color};
    color: white;
    font-size: 20px;
    font-family: "avenir-black";
    line-height: 22px;
    @media (max-width: 768px) {
           font-size: ${(props) => props.smFont};
        }
`;