import React from "react"
import VHCenter from "./elements/VHCenter"
import ImgHeaderDiv from "./elements/ImgHeaderDiv";
import { graphql } from "gatsby";
import styled from 'styled-components'
import './imgHeader.css'
import img from '../assets/ourstory/Our-Story.jpg'

const Div = styled.div`
    /* // max-width: 100%;
    // height: auto;
    // width: auto;
    // margin:auto;
    // display:block; */
    background-image: url(${(props) => props.image});
`

const ImgHeader = ({ title, image, position }) => {

    return (
        <div>
            {/*<ImgHeaderDiv image={image} position={position}>*/}
            {/*    <VHCenter style={{ display: "flex", justifyContent: "center" }}>*/}
            {/*        <h1 className="avenir-heavy" style={{ color: "#febf20" }}>{title}</h1>*/}
            {/*    </VHCenter>*/}
            {/*</ImgHeaderDiv>*/}
            {/* <div id="logo">*/}
            {/*    <img style={{width:"100%"}} src={img} alt="Image of Traffic Monsoon"/>*/}
            {/*</div>*/}

            <div class="image">
                <img style={{ width: "100%" }} src={image} alt="" />
                <div class="text">
                    <h1 className="avenir-heavy" style={{ color: "#febf20" }}>{title}</h1>
                </div>
            </div>

        </div>
    )
}

export default ImgHeader
