/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import $ from 'jquery';
import { FaArrowCircleUp } from 'react-icons/fa'

import "./layout.css"
import Navigation from "./navbar/Navbar";
import Footer from "./Footer"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const back = () => {
    $("html, body").animate({ scrollTop: 0 }, 1000);
  }

  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
          $('.back-to-top').fadeIn();
        } else {
          $('.back-to-top').fadeOut();
        }
      });
    });
  })



  return (
    <>
      <Navigation siteTitle={data.site.siteMetadata.title} />
      <div
      >
        <main>{children}</main>
        <FaArrowCircleUp style={{ color: "#ed6c2b" }} class="back-to-top" onClick={back} />
        {/* <button class="back-to-top" onClick={back}>Top</button> */}
        <Footer />
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
