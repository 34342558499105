import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import logo from '../assets/white-logo.png'
import styled from 'styled-components'
import VHCenter from "../components/elements/VHCenter"
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram } from 'react-icons/fa'

const Facebook = styled(FaFacebookF)`
    color: #84b8cd;
    float: right;
    font-size: 32px;
    margin: 0% 2%;
    @media (max-width: 768px) {
    margin-right:5%;
    margin-left:5%;
  }
`;
const LinkedinIn = styled(FaLinkedinIn)`
    color: #84b8cd;
    float: right;
    font-size: 35px;
    margin: 0% 2%;
    @media (max-width: 768px) {
    margin-right:5%;
    margin-left:5%;
  }
`;
const Instagram = styled(FaInstagram)`
    color: #84b8cd;
    float: right;
    font-size: 35px;
    margin: 0% 2%;
    /* padding-right:5%; */
    @media (max-width: 768px) {
    display:flex;
    justify-content:center;
    padding:5%;
  }
`;


export default function Footer() {
    const data = useStaticQuery(graphql`
    query {
        footer: allContentfulBlocks(
            filter: { path: { eq: "/" } }
            sort: { fields: orderId }
          ) {
            edges {
              node {
                body {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
    }
`)
    const footerText = data.footer.edges[0].node.body.childContentfulRichText.html

    return (
        <footer class="page-footer font-small blue pt-4 mb-4" style={{ borderTop: "1px solid black" }}>
            <div class="container">
                <div className="row">
                    <div className="col-sm-12 col-md-4 p-3 sm-center ">
                        <VHCenter>
                            <img width="210px" height="50px" src={logo} alt="happiness ftt logo" />
                        </VHCenter>
                    </div>
                    <div className="col-sm-12 col-md-4 footer-copyright text-center">
                        <VHCenter dangerouslySetInnerHTML={{ __html: footerText }}>
                        </VHCenter>
                    </div>
                    <div className="col-sm-12 col-md-4 footer-copyright text-center sm-center py-4" style={{ float: "right" }}>
                        <div>

                            <a href="https://www.linkedin.com/company/the-happiness-fitt/about/" target="_blank"><LinkedinIn /></a>

                        </div>
                        <div style={{ marginRight: "5%", marginLeft: "5%" }}>

                            <a href="https://www.instagram.com/thehappinessftt/" target="_blank"><Instagram /></a>

                        </div>
                        <div>

                            <a href="https://www.facebook.com/thehappinessfitt/" target="_blank"><Facebook /></a>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
