import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "./navbar2.css"
import "./navbar.scss"
import logo from "../../assets/logo.png"
import { navigate } from "gatsby"
import styled from "styled-components"

const Item = styled.p`
  color: rgb(97, 94, 94);
  :hover{
    color:#ffc107;;
  }
`
const CusDiv = styled.div`
  color: rgb(97, 94, 94);
  :hover{
    color:#ffc107;;
  }
`


export default class Navbar extends Component {
  render() {
    return (
      <div className="pt-1">
        <header id="hf-top-header" className="row pr-0 mr-0">
          <div className="logo ml-3">
            <Link to="/">
              <img src={logo} alt="happiness fitt logo" />
            </Link>
          </div>
          <div className="thf-navbar">
            <div className="subnav navbtns">
              <Link>
                <button className="subnavbtn">
                  <Item>HOME</Item>
                </button>
              </Link>
            </div>
            <div className="subnav navbtns">
              <button className="subnavbtn">
                <Item>WHY HAPPINESS</Item> <span className="arrow"></span>
              </button>
              <div className="nav-content">
                <div className="mt-1"><Link to="/individuals">Individuals</Link></div>
                <div className="mt-1"><Link to="/corporates">Corporate</Link></div>
                <div className="mt-1"><Link to="/kids">Kids</Link></div>
              </div>
            </div>
            <div className="subnav navbtns">
              <Link to="/ourstory">
                <button className="subnavbtn">
                  <Item> OUR STORY </Item>
                </button>
              </Link>
            </div>
            <div className="subnav navbtns">
              <Link to="/discover">
                <button className="subnavbtn">
                  <Item>DISCOVER US </Item>
                </button>
              </Link>
            </div>
            <div className="subnav navbtns">
              <Link to="/engage">
                <button className="subnavbtn">
                  <Item>ENGAGE US </Item>
                </button>
              </Link>
            </div>

            <div className="subnav navbtns">
              <Link to="/contact">
                <button className="subnavbtn">
                  <Item>CONTACT US </Item>
                </button>
              </Link>
            </div>
            <a id="main-menu-toggle" href="#thf-sidenavmenu">
              <i style={{ color: "#ffc107" }} className="material-icons">menu</i>
            </a>
          </div>
          <div id="thf-sidenavmenu" className="thf-sidenavmenu">
            <a href="#main-menu-toggle" className="closebtn">
              &times;
          </a>

            <Link to="/">HOME</Link>
            <div className="thf-sidenav-subnav">
              <a style={{ color: "#f9b600" }} className="accordion">
                WHY HAPPINESS
            </a>
              <div className="panel">
                <Link to="/individuals">INDIVIDUALS</Link>
                <Link to="/corporates">CORPORATE</Link>
                <Link to="/kids">KIDS</Link>
              </div>
            </div>
            <div className="thf-sidenav-subnav">
              <Link to="/ourstory" className="accordion">
                OUR STORY
            </Link>
            </div>
            <div className="thf-sidenav-subnav">
              <Link to="/discover" className="accordion">
                DISCOVER US
            </Link>
            </div>
            <div className="thf-sidenav-subnav">
              <Link to="/engage" className="accordion" >
                ENGAGE WITH US
            </Link>
            </div>
            <div className="thf-sidenav-subnav">
              <Link to="/contact" className="accordion">
                CONTACT US
            </Link>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
